<template>
  <div class="gameRanking">
    <div class="header">
      <div
        class="left"
        v-if="!$route.query.platform"
        @click="$router.back()"
      ></div>
    </div>
    <div class="banner">
      <div class="left">
        <img src="../../assets/images/sanyingzhanlvbu.png" alt="" />
      </div>
      <div class="right">
        <img src="../../assets/images/shijiebei/jiangbei.png" alt="" />
      </div>
    </div>
    <div class="list">
      <div class="tab">
        <div class="tabItem" v-for="item in tabList" :key="item">
          {{ item }}
        </div>
      </div>
      <div
        class="listItem"
        :class="
          key === 0 ? 'one' : key === 1 ? 'two' : key === 2 ? 'there' : null
        "
        v-for="(item, key) in list"
        :key="key"
      >
        <div>{{ key + 1 }}</div>
        <div>{{ item.name || '--' }}</div>
        <div>{{ item.totalfire || '--' }}</div>
        <div>{{ item.nickName || '--' }}</div>
        <div>{{ item.huoliFee || '--' }}</div>
      </div>
    </div>
    <div class="list">
      <div class="tab">
        <div class="tabItem" v-for="item in tabList1" :key="item">
          {{ item }}
        </div>
      </div>
      <div
        class="listItem"
        :class="
          key === 0 ? 'one' : key === 1 ? 'two' : key === 2 ? 'there' : null
        "
        v-for="(item, key) in xiaoduiList"
        :key="key"
      >
        <div>{{ key + 1 }}</div>
        <div>{{ item.nickName || '--' }}</div>
        <div>{{ item.huoliFee || '--' }}</div>
      </div>
    </div>
    <div class="info">*贡献值计算方法：</div>
    <div class="info">贡献值 = 购买金额-委托寄卖金额</div>
    <div class="info">火力值大于1的会员，才可以享受奖励权益！</div>

    <div class="list" style="margin-top: 30px">
      <div class="tab">
        <div class="tabItem">天之娇子</div>
      </div>
      <div class="listItem">
        <div>{{ hecengTop.nickName || '--' }}</div>
      </div>
    </div>
    <div class="info">*使用球队兑换交子top1</div>

    <div class="list" style="margin-top: 30px">
      <div class="tab">
        <div class="tabItem">三姓家奴</div>
      </div>
      <div class="listItem">
        <div>{{ sxjn.nickName || '--' }}</div>
      </div>
    </div>
    <div class="info">*【吕布战队】火力值最低</div>

    <div class="list" style="margin-top: 30px">
      <div class="tab">
        <div class="tabItem">背信弃义</div>
      </div>
      <div class="listItem">
        <div>{{ bxqi.nickName || '--' }}</div>
      </div>
    </div>
    <div class="info">*【三英战队】火力值最低</div>
  </div>
</template>

<script>
export default {
  name: 'gameRanking',
  data() {
    return {
      tabList: ['排名', '小组', '战队火力值', 'MVP', '贡献值'],
      tabList1: ['排名', '昵称', '火力值'],
      list: [],
      xiaoduiList: [],
      hecengTop: {},
      sxjn: {},
      bxqi: {},
    };
  },
  mounted() {
    this.gameRanking();
  },
  methods: {
    gameRanking() {
      this.$api.gameRanking().then((e) => {
        if (e.code === 0) {
          this.list = e.data;
          this.xiaoduiList = e.xiaodui;
          this.hecengTop = e.hecengTop;
          this.sxjn = e.sxjn;
          this.bxqi = e.bxqi;

          if (this.list.length < 2) {
            const num = 2 - this.list.length;
            for (let i = 0; i < num; i++) {
              this.list.push({
                name: '--',
                totalfire: '--',
                nickName: '--',
                huoliFee: '--',
              });
            }
          }
          if (this.xiaoduiList.length < 10) {
            const num = 10 - this.xiaoduiList.length;
            for (let i = 0; i < num; i++) {
              this.xiaoduiList.push({
                huoliFee: '--',
                nickName: '--',
              });
            }
          }
        } else {
          this.$toast(e.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.gameRanking {
  background: url(../../assets/images/shijiebei/bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: auto;
  padding-bottom: 20px;
  .header {
    height: 50px;
    padding: 0 20px;
    position: relative;
    .left {
      width: 10px;
      height: 10px;
      border-top: 1px solid #fff;
      border-left: 1px solid #fff;
      top: 50%;
      position: absolute;
      transform: rotate(-45deg) translateY(-50%);
    }
  }
  .banner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 17px 0 38px;
    .left {
    }
    .right {
    }
    img {
      max-width: 100%;
    }
  }
  .list {
    background: #e3eafe;
    border-radius: 10px;
    margin: 0 17px 15px;
    padding: 10px 0 0;
    min-height: 100px;
    &.minHeight {
      min-height: 200px;
    }
    .tab {
      display: flex;
      .tabItem {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #644393;
        flex: 1;
        text-align: center;
        padding: 10px 0;
        &:nth-child(3) {
          flex: 2;
        }
        &:nth-child(4),
        &:nth-child(5) {
          flex: 1.5;
        }
      }
    }
    .listItem {
      display: flex;
      align-items: center;
      padding: 10px 0;
      &:nth-child(even) {
        background: #d3e0ff;
      }
      div {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #977363;
        flex: 1;
        text-align: center;
        min-width: 0px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        padding: 0 3px;
        &:nth-child(3) {
          flex: 2;
        }
        &:nth-child(4),
        &:nth-child(5) {
          flex: 1.5;
        }
      }
      &.one {
        div {
          color: #d14546;
        }
      }
      &.two {
        div {
          color: #fc9120;
        }
      }
      &.there {
        div {
          color: #e1b200;
        }
      }
    }
  }
  .info {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 18px;
    padding-left: 30px;
  }
}
</style>
